interface MenuItem {
  text: string
  url: string
  permission: string
  icon: string
  submenu?: MenuItem[]
  managed?: boolean
}

export const mainMenu: MenuItem[] = [
  {
    text: "Посты",
    url: "/posts/",
    permission: "posts",
    icon: "icons/well.png",
    managed: true,
  },
  {
    text: "ТМЦ",
    url: "#",
    permission: "documents",
    icon: "icons/wh.png",
    submenu: [
      {
        text: "ТТН",
        url: "/documents/ttn/",
        permission: "documents.view_doc",
        icon: "icons/ttn_white.png",
      },
      {
        text: "Инвентаризации",
        url: "/documents/inventory/",
        permission: "documents.view_invetorydoc",
        icon: "icons/inventory_white.png",
      },
      {
        text: "Остатки по складам",
        url: "/warehouse/warehouses_balance/",
        permission: "",
        icon: "icons/ostatki_white.png",
      },
      {
        text: "Партии х/р",
        url: "/warehouse/wt_parties/",
        permission: "",
        icon: "icons/ostatki_white.png",
      },
      {
        text: "Мин. запас х/р",
        url: "/chemie/minimal/",
        permission: "",
        icon: "icons/minost_white.png",
      },
      {
        text: "Материалы",
        url: "/warehouse/matherials/",
        permission: "",
        icon: "icons/chem.png",
      },
      {
        text: "QR бланки",
        url: "/warehouse/qr_list/",
        permission: "",
        icon: "icons/qr_white.png",
      },
    ],
  },
  {
    text: "Скважины",
    url: "/wells/",
    permission: "wells.view_well",
    icon: "icons/archive_well_white.png",
  },
  {
    text: "Договоры",
    url: "/warehouse/contracts/",
    permission: "warehouse.view_dogovor",
    icon: "icons/dogovor.png",
  },
  {
    text: "Сотрудники",
    url: "/omacpro/person/",
    permission: "omacpro",
    icon: "icons/personel_white.png",
  },
  {
    text: "Справочники",
    url: "/dictionaries/",
    permission: "dictionaries",
    icon: "icons/ditionaries_new_white.png",
  },
  {
    text: "Уведомления",
    url: "/notifications/",
    permission: "notifications.view_notification",
    icon: "icons/notification.png",
  },
  {
    text: "Отчёты",
    url: "/reports/",
    permission: "reports",
    icon: "icons/analytics.png",
  },
  {
    text: "Задачи",
    url: "#",
    permission: "",
    icon: "icons/tasks.png",
  },
  {
    text: "Склады",
    url: "/warehouse/warehouses/",
    permission: "warehouse.view_subject",
    // [
    //   (user) => accessWarehouseViewSubject(user),
    //   (user) => notTestUser(),
    // ],
    icon: "icons/wh_loc.png",
  },
  {
    text: "Пользователи",
    url: "/accounts/user/",
    permission: "superuser",
    icon: "icons/user.png",
  },
  {
    text: "ТБ",
    url: "#",
    permission: "tb_info.view_tb",
    icon: "icons/posts.png",
    submenu: [
      {
        text: "Тип ТБ",
        url: "/tb_info/tbtype/",
        permission: "tb_info.view_tb",
        icon: "icons/safety.png",
      },
      {
        text: "Протокол ТБ",
        url: "/tb_info/tbcheckperson/",
        permission: "tb_info.view_tb",
        icon: "icons/inventory_white.png",
      },
      {
        text: "Общий список",
        url: "/tb_info/person_info/",
        permission: "tb_info.view_tb",
        icon: "icons/personel_white.png",
      },
      {
        text: "СИЗ",
        url: "/tb_info/siztype/",
        permission: "tb_info.view_tb",
        icon: "icons/equipment_icon.png",
      },
      {
        text: "Профиль по СИЗ",
        url: "/tb_info/personsizdata/",
        permission: "tb_info.view_tb",
        icon: "icons/user.png",
      },
      {
        text: "выдача СИЗ",
        url: "/tb_info/sizissue/",
        permission: "tb_info.view_tb",
        icon: "icons/list_icon.png",
      },
    ],
  },
  {
    text: "Админка",
    url: "#",
    permission: "superuser",
    icon: "icons/user.png",
    submenu: [
      {
        text: "StDocs",
        url: "/documents/stdocs/",
        permission: "superuser",
        icon: "icons/ttn_white.png",
      },
      {
        text: "Изменения",
        url: "/warehouse/changelogs/",
        permission: "superuser",
        icon: "icons/ttn_white.png",
      },
    ],
  },
]
